import {Nullable} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {BreedingDetails} from 'api/gen/BreedingDetails';
import {PagedModelPlanningAnimal} from 'api/gen/PagedModelPlanningAnimal';
import {PagedModelPlanningBreeding} from 'api/gen/PagedModelPlanningBreeding';
import {State} from 'app/setup';

export const simpleBreedingSelector = {
    animalsInBreedingPage: ({breeding}: State): Opt<PagedModelPlanningAnimal> => breeding.animalsInBreedingPage,
    breedingPage: ({breeding}: State): Opt<PagedModelPlanningBreeding> => breeding.breedingPage,
    breeding: ({breeding}: State): Nullable<BreedingDetails> => breeding.breeding,
    fetchingIds: ({breeding}: State): Array<number> => breeding.fetchingIds,
};

